import { LanguageStrings } from './base-strings';

export const hk: LanguageStrings = {
  /** Routes */
  'routes.review': '/',
  'routes.registration': '/registration',
  'routes.confirm': '/confirm',
  'routes.checkinpayment': '/checkinpayment',
  'routes.confirmpayment': '/confirmpayment',
  'routes.hdfForm': '/hdf-form',
  'routes.search': '/search',

  /** Function Name */
  'review.menu.name': '檢閱',
  'hdfForm.menu.name': '健康申報',
  'registration.menu.name': '登記',
  'confirm.menu.name': '確認',
  'error.menu.name': '錯誤',
  'search.menu.name': '搜尋',

  /** Page content */
  'home.title': '主頁',
  'home.content': '歡迎進入我的網頁',

  'summary.title': '總結',
  'summary.content': '詳情可以瀏覧以下連結 {medium} 以及 {devto}.',

  'loading.title': 'Loading...',
  'loading.content': '正在載入你的預訂詳細信息，請稍等',
  'error.title': '錯誤',
  'error.content': '發生錯誤，請稍𢓭再試。',
  'error.required': '必需塡寫',
  'error.dateOfBirth.day': '日期無效',
  'error.message2': '發生系統錯誤，請與系統管理員聯繫以獲取詳細信息。',
  'error.message.aleadySubmit': '您已經登記了所需資料，謝謝！',
  'error.message.notFound': '找不到資料！',
  'error.message.urlInvalid': '連結已失效！',
  'error.message.flightNumber': '航班編號例如CX231',
  'error.message.flightArrivalTime': '請輸入有效時間',
  'error.message.preference': '請選擇您的偏好',

  /** Links */
  'links.medium':
    'https://medium.com/prototyped/multi-language-routing-in-react-d7eb7a0688e9',
  'links.dev.to': 'https://dev.to/prototyp/multi-language-routing-in-react-k9l',

  /** Payment T&C */
  'payment.t&c':
    '本人同意不會免除對此帳單的責任，並同意個人承擔這些費用的全額。',

  /** Choice Befor Pay Room Rate */
  'choice.roomrateTitleOptional':
    '為了縮短抵達酒店時辦理入住時間，您是否要現在支付房間費用？',
  'choice.roomrateTitleCompulsory':
    '為了令您有更快捷的入住登記體驗，請預先支付房間費用。謝謝！',
  'choice.roomrate.no': '否，我到達酒店時才支付房間費用。',
  'choice.roomrate.title': '房間費用明細',

  /** Choice */
  'choice.depositTitleOptional':
    '為了縮短抵達酒店時辦理入住時間，您是否要現在支付定金？',
  'choice.depositTitleCompulsory':
    '為了縮短抵達酒店時辦理入住時間，請您現在支付定金？',
  'choice.yes': '是',
  'choice.no': '否，我到達酒店時才支付定金。',
  'choice.deposit.title': '押金明細',
  'choice.deposit': '押金',
  'choice.roomrate': '房價',
  'choice.total': '合共',
  'choice.warning': '你必需同意以上條款才能繼續。',
  'choice.askSaveCreditCard':
    '本人授權酒店在信用卡中扣除商品及服務相關的費用。本人同意商家可能會保存個人信息以供日後付款。本人亦明白這項條款可因應要求隨時撤銷。',

  /** CheckInPayment */
  'checkinpayment.title': '請支付房價',
  'checkinpayment.ok': '好',
  'checkinpayment.selectpaymentmethod': '請選擇付款方式',
  'checkinpayment.useSavedCard': '使用已儲存信用卡支付',
  'checkinpayment.success': '付款成功',
  'checkinpayment.merchantRef': '交易編號：',
  'checkinpayment.mini.success':
    'The payment for your reservation is Successfully Done.',
  'checkinpayment.mini.success2':
    'We look forward to welcoming you and remain committed to making your stay a smooth and memorable experience.',
  'checkinpayment.mini.success3': 'See You Soon!',
  'checkinpayment.error': '交易失敗，請重新付款',
  'checkinpayment.bankError': '交易被拒絕，請聯絡您的銀行',
  'checkinpayment.invoice': '我們會把發票傳送到你的電郵',
  'checkinpayment.continue': '繼續',
  'checkinpayment.pleasescanqrcode': '請用APP掃描二維碼',
  'checkinpayment.payInNewTab': '請在新分頁完成付款',
  'checkinpayment.payInNewTabNotOpened':
    '如果新分頁沒有自動打開，請按下面的按鈕開始',
  'checkinpayment.startPayment': '開始付款',

  /** Review */
  'review.title': '請查看您的預訂詳細信息，然後單擊“確認”按鈕',
  'review.confirm.no': '確認號碼',
  'review.guest.name': '賓客姓名',
  'review.checkin.date': '入住和退房日期',
  'review.reserved.room.type': '預留房間類型',
  'review.total.room.rate': '房價總費用',
  'review.preferences': '優先',
  'review.note':
    '請核對您的預訂姓名與您的身份證件/護照相同。如您有任何疑問，請發送電郵致resvn@royalplaza.com.hk與我們聯繫。',
  'review.video.note': '在進行預先登記前，請點擊 {play} 按鈕觀看介紹{video}。',
  'review.video.play': '播放',
  'review.video.video': '短片',
  'review.room.type.RSK': '高級客房',
  'review.room.type.RST': '高級客房',
  'review.room.type.RDK': '豪華客房',
  'review.room.type.RDT': '豪華客房',
  'review.room.type.RDH': '豪華客房',
  'review.room.type.RPK': '尊貴客房',
  'review.room.type.RPT': '尊貴客房',
  'review.room.type.RPH': '尊貴客房',
  'review.room.type.CDK': '行政豪華客房',
  'review.room.type.CDT': '行政豪華客房',
  'review.room.type.CDH': '行政豪華客房',
  'review.room.type.CPK': '行政尊貴客房',
  'review.room.type.CPT': '行政尊貴客房',
  'review.room.type.CPH': '行政尊貴客房',
  'review.room.type.STK': '特色套房',
  'review.room.type.STH': '特色套房',
  'review.room.type.SJK': '高級套房',
  'review.room.type.SJT': '高級套房',
  'review.room.type.SJH': '高級套房',
  'review.room.type.SDK': '豪華套房',
  'review.room.type.SDH': '豪華套房',
  'review.room.type.SRK': '皇室套房',
  'review.room.type.SPK': '總統套房',
  'review.room.type.TDK': '天際豪華客房',
  'review.room.type.TDH': '天際豪華客房',
  'review.room.type.THK': '天際海景客房',
  'review.room.type.THQ': '天際海景客房',
  'review.room.type.TSK': '天際半海景客房',
  'review.room.type.TSQ': '天際半海景客房',
  'review.room.type.HJH': '天際高級套房',
  'review.room.type.HVS': '天際海景套房',
  'review.room.type.DBD': '高級客房',
  'review.room.type.TWD': '高級客房',
  'review.room.type.QEA': '豪華客房',
  'review.room.type.TWA': '豪華客房',
  'review.room.type.QEE': '尊貴豪華客房',
  'review.room.type.TWE': '尊貴豪華客房',
  'review.room.type.KHC': '家庭客房',
  'review.room.type.KHA': '尊貴家庭客房',
  'review.room.type.QEC': '尊貴鉑爾曼客房',
  'review.room.type.TWC': '尊貴鉑爾曼客房',
  'review.room.type.QEAEF': '豪華行政客房',
  'review.room.type.KGAEF': '尊貴行政客房',
  'review.room.type.SKC': '柏寧套房',
  'review.room.type.SUP': '高級雙人客房',
  'review.room.type.AWM': 'Anti-window (4 Ft)',
  'review.room.type.TD': 'Tower Double',
  'review.room.type.DLX': 'Deluxe Room',
  //DWC
  'review.room.type.SUD': '高級客房',
  'review.room.type.SUH': '高級客房',
  'review.room.type.SUK': '高級客房',
  'review.room.type.SUT': '高級客房',
  'review.room.type.GCUD': '高級客房 (連景觀)',
  'review.room.type.GCUK': '高級客房 (連景觀)',
  'review.room.type.GCUT': '高級客房 (連景觀)',
  'review.room.type.PDEQ': '尊尚銅鑼灣城市景觀客房',
  'review.room.type.PDED': '尊尚銅鑼灣城市景觀客房',
  'review.room.type.PDEK': '尊尚銅鑼灣城市景觀客房',
  'review.room.type.PDET': '尊尚銅鑼灣城市景觀客房',
  'review.room.type.PDCK': '尊尚跑馬地馬場景觀客房',
  'review.room.type.PDCQ': '尊尚跑馬地馬場景觀客房',
  'review.room.type.PDCT': '尊尚跑馬地馬場景觀客房',
  'review.room.type.PDCD': '尊尚跑馬地馬場景觀客房',
  'review.room.type.DTR': '豪華三人客房',
  'review.room.type.DFQ': '豪華家庭四人客房',
  'review.room.type.EFQ': '豪華家庭四人客房',
  'review.room.type.ESK': '行政套房',
  'review.room.type.EST': '行政套房',

  /** Confirm */
  'confirm.title': '預先登記程序已完成',
  'confirm.reminder': '您可以在到達我們的酒店時使用客服機械人。',
  'confirm.reminder2': '請在登記入住時出示登記二維碼。',
  'confirm.thankyou': '謝謝',
  'confirm.qrcode.err': '二維碼數值不正確',
  'confirm.notice.title': '特别通告:',
  'confirm.notice.covid19.title': '針對新型冠狀病毒的預防措施',
  'confirm.notice.covid19.detail': '有關更多詳細信息，請查看 ',
  'confirm.notice.covid19.link':
    'https://www.royalplaza.com.hk/hk/precautionary-measures-for-novel-coronavirus/',
  'confirm.notice.policy.title': '隱私政策聲明和免責聲明',
  'confirm.notice.policy.detail': '有關更多詳細信息，請查看 ',
  'confirm.notice.policy.link':
    'https://www.royalplaza.com.hk/hk/disclaimer-privacy-statement/',
  'confirm.deposit':
    '為了加快入住手續，已全額預付房費的客人無需支付押金。任何額外費用需要直接支付，不能計入房帳。',

  /** Registration Page content */
  'reg.title': '預先登記',
  'reg.reminder': '請在下面填寫您的個人詳細信息，然後單擊“確認”按鈕',
  'reg.wechat.reminder': '請使用您的微信掃描以下二維碼。',
  'reg.firstName': '名字',
  'reg.lastName': '姓氏',
  'reg.email': '個人電子郵件',
  'reg.address1': '地址 1',
  'reg.address2': '地址 2',
  'reg.pleaseSelect': '請選擇',
  'reg.country': '國家或地區',
  'reg.nationality': '國籍',
  'reg.zip.code': '郵政編碼',
  'reg.arrival.time': '預計到達時間',
  'reg.flightArrival.time': '航班抵港時間 / 預計抵港時間',
  'reg.arrival.hr': '時',
  'reg.arrival.min': '分',
  'reg.arrival.subject': '需視乎供應情况而定',
  'reg.tel': '手提電話號碼',
  'reg.passportNumber': '護照號碼',
  'reg.dateOfBirth': '出生日期',
  'reg.flightNumber': '航班編號',
  'reg.flightArrivalTime': '到達時間',
  'reg.evoucher': '接收酒店優惠及推廣資訊（透過電子郵件，電話訊息及社交媒體）',
  'reg.evoucher.yes/no': '接受/拒絕',
  'reg.evoucher.yes': '接受',
  'reg.evoucher.no': '拒絕',
  'reg.method.inst.msg': '即時通訊方式',
  'reg.method.inst.msg.select': '選取通訊式',
  'reg.method.inst.msg.sms': '短訊',
  'reg.method.inst.msg.email': '電子郵件',
  'reg.method.inst.msg.whatsapp': 'Whatsapp',
  'reg.method.inst.msg.wechat': '微信',

  'hdf.ask': '要填寫健康申報表嗎?',
  'hdf.title': '健康申報表',
  'hdf.highRiskWarning.title': '覆核內容',
  'hdf.highRiskWarning.message': '請重新檢視或聯絡酒店獲取進一步的協助',
  'hdf.sign.title': '我聲明所填寫的資料皆為屬實',
  'hdf.sign.text': '請在下方簽署:',
  'hdf.sign.clear': '清除',

  'hdf.btn.fill': '填寫',
  'hdf.btn.addGuest': 'Add Guest',
  'hdf.btn.signAndConfirm': '簽署並確認',
  'hdf.btn.submitForm': '確認',
  'hdf.btn.back': '返回',
  'hdf.btn.backToReview': '返回注册',

  'hdf.guestName': '客戶名稱',
  'hdf.accompanyName': '伴隨人',
  'hdf.familyName': '姓氏',
  'hdf.firstName': '名字',
  'hdf.dateOfBirth': '出生日期',
  'hdf.sex': '性別',
  'hdf.sex.m': '男',
  'hdf.sex.f': '女',
  'hdf.passport': '護照/身份證號碼',
  'hdf.nationality': '國籍地區',
  'hdf.comeFrom': '旅遊自',
  'hdf.arrivalDate': '抵港日期',
  'hdf.placesVisitedInSevenDays': '請列出過去七日到訪的國家地區和城市',
  'hdf.contactWithCovid': '在過去七天內閣下有否與確診新冠肺炎人士接觸過?',
  'hdf.contactWithCovid.yes': '是',
  'hdf.contactWithCovid.no': '否',
  'hdf.symptom': '閣下有沒有以下症狀',
  'hdf.symptom.no': '否',
  'hdf.symptom.fever': '發燒',
  'hdf.symptom.breathingDifficulty': '呼吸困難',
  'hdf.symptom.cough': '咳',
  'hdf.symptom.diarrhoea': '腹瀉',
  'hdf.symptom.headache': '頭痛',
  'hdf.symptom.musclePain': '肌肉痛',
  'hdf.symptom.runnyNose': '流鼻水',
  'hdf.symptom.soreThroat': '咽喉痛',
  'hdf.symptom.stuffyNose': '鼻塞',
  'hdf.symptom.vomiting': '嘔吐',
  'hdf.contact': '未來七天的聯絡方式',
  'hdf.contact.phone': '電話/手提號碼',
  'hdf.email': '電郵',
  'hdf.address': '聯絡地址',
  'btn.confirm': '確認',
  'btn.hdf.form': '健康申報表',
  'btn.reset': '重設',
  'btn.cancel': '取消',

  'country.AF': '阿富汗',
  'country.AL': '阿爾巴尼亞',
  'country.DZ': '阿爾及利亞',
  'country.AS': '美屬薩摩亞',
  'country.AD': '安道爾',
  'country.AO': '安哥拉',
  'country.AI': '安圭拉/聖基茨',
  'country.AG': '安提瓜和巴布達',
  'country.AR': '阿根廷',
  'country.AM': '亞美尼亞',
  'country.AW': '阿魯巴島',
  'country.AU': '澳大利亞',
  'country.AT': '奧地利',
  'country.AZ': '阿塞拜疆',
  'country.BS': '巴哈馬',
  'country.BH': '巴林',
  'country.BD': '孟加拉國',
  'country.BB': '巴巴多斯',
  'country.BY': '白俄羅斯',
  'country.BE': '比利時',
  'country.BZ': '伯利茲',
  'country.BJ': '貝寧',
  'country.BM': '百慕大',
  'country.BT': '不丹',
  'country.BO': '玻利維亞',
  'country.BQ': '博奈爾島，薩巴島，聖尤斯達休',
  'country.BA': '波斯尼亞黑塞哥維那',
  'country.BW': '博茨瓦納',
  'country.BV': '布維島',
  'country.BR': '巴西',
  'country.IO': '英屬印度洋領地',
  'country.BN': '文萊達魯薩蘭國',
  'country.BG': '保加利亞',
  'country.BF': '布基納法薩',
  'country.BI': '布隆迪',
  'country.KH': '柬埔寨',
  'country.CM': '喀麥隆',
  'country.CA': '加拿大',
  'country.CV': '佛得角',
  'country.KY': '開曼群島',
  'country.CF': '中非共和國',
  'country.TD': '乍得',
  'country.CL': '智利',
  'country.CN': '中國',
  'country.CX': '聖誕島',
  'country.CC': '科科斯（基林）群島',
  'country.CO': '哥倫比亞',
  'country.KM': '科摩羅',
  'country.CG': '剛果',
  'country.CD': '剛果民主共和國',
  'country.CK': '庫克島',
  'country.CR': '哥斯達黎加',
  'country.CI': '科特迪瓦',
  'country.HR': '克羅地亞',
  'country.CU': '古巴',
  'country.CW': '庫拉索',
  'country.CY': '塞浦路斯',
  'country.CZ': '捷克共和國',
  'country.DK': '丹麥',
  'country.DJ': '吉布地',
  'country.DM': '多米尼加',
  'country.DO': '多明尼加共和國',
  'country.TP': '東帝汶',
  'country.EC': '厄瓜多爾',
  'country.EG': '埃及',
  'country.SV': '薩爾瓦多',
  'country.GQ': '赤道幾內亞',
  'country.ER': '厄立特里亞',
  'country.EE': '愛沙尼亞',
  'country.ET': '埃塞俄比亞',
  'country.FK': '福克蘭群島',
  'country.FO': '法羅群島',
  'country.FJ': '斐濟',
  'country.FI': '芬蘭',
  'country.FR': '法國',
  'country.GF': '法屬圭亞那',
  'country.PF': '法屬波利尼西亞',
  'country.TF': '法國南部地區',
  'country.GA': '加蓬',
  'country.GM': '岡比亞',
  'country.GE': '佐治亞州',
  'country.DE': '德國',
  'country.GH': '加納',
  'country.GI': '直布羅陀',
  'country.GB': '大不列顛',
  'country.GR': '希臘',
  'country.GL': '格陵蘭',
  'country.GD': '格林納達',
  'country.GP': '瓜德羅普島/聖馬丁',
  'country.GU': '關島',
  'country.GT': '危地馬拉',
  'country.GN': '幾內亞',
  'country.GW': '幾內亞比紹',
  'country.GY': '圭亞那',
  'country.HT': '海地',
  'country.HM': '克島同麥當勞群島',
  'country.HN': '洪都拉斯',
  'country.HK': '香港特別行政區',
  'country.HU': '匈牙利',
  'country.IS': '冰島',
  'country.IN': '印度',
  'country.ID': '印度尼西亞',
  'country.IR': '伊朗',
  'country.IQ': '伊拉克',
  'country.IE': '愛爾蘭',
  'country.IM': '馬恩島',
  'country.IL': '以色列',
  'country.IT': '意大利',
  'country.JM': '牙買加',
  'country.JP': '日本',
  'country.JO': '約旦',
  'country.KZ': '哈薩克斯坦',
  'country.KE': '肯尼亞',
  'country.KI': '基里巴斯',
  'country.KR': '韓國',
  'country.KP': '朝鮮民主主義人民共和國',
  'country.KW': '科威特',
  'country.KG': '吉爾吉斯斯坦',
  'country.LA': '老撾',
  'country.LV': '拉脫維亞',
  'country.LB': '黎巴嫩',
  'country.LS': '萊索托',
  'country.LR': '利比里亞',
  'country.LY': '阿拉伯利比亞民眾國',
  'country.LI': '列支敦士登',
  'country.LT': '立陶宛',
  'country.LU': '盧森堡',
  'country.MO': '澳門特別行政區',
  'country.MK': '馬其頓',
  'country.MG': '馬達加斯加',
  'country.MW': '馬拉維',
  'country.MY': '馬來西亞',
  'country.MV': '馬爾代夫',
  'country.ML': '馬里',
  'country.MT': '馬耳他',
  'country.MH': '馬紹爾群島',
  'country.MQ': '馬提尼克島',
  'country.MR': '毛里塔尼亞',
  'country.MU': '毛里求斯',
  'country.YT': '馬約特島',
  'country.MX': '墨西哥',
  'country.FM': '密克羅尼西亞聯邦',
  'country.MI': '中途島',
  'country.MD': '摩爾多瓦',
  'country.MC': '摩納哥',
  'country.MN': '蒙古',
  'country.ME': '黑山共和國',
  'country.MS': '蒙特塞拉特',
  'country.MA': '摩洛哥',
  'country.MZ': '莫桑比克',
  'country.MM': '緬甸',
  'country.NA': '納米比亞',
  'country.NR': '瑙魯',
  'country.NP': '尼泊爾',
  'country.NL': '荷蘭',
  'country.AN': '荷屬安的列斯',
  'country.NC': '新喀裡多尼亞',
  'country.NZ': '新西蘭',
  'country.NI': '尼加拉瓜',
  'country.NE': '尼日爾',
  'country.NG': '奈及利亞',
  'country.NU': '紐埃',
  'country.NF': '諾福克島',
  'country.MP': '北馬里亞納群島',
  'country.NO': '挪威',
  'country.OM': '阿曼',
  'country.PK': '巴基斯坦',
  'country.PW': '帛琉',
  'country.PA': '巴拿馬',
  'country.PG': '巴布亞新幾內亞',
  'country.PY': '巴拉圭',
  'country.PE': '秘魯',
  'country.PH': '菲律賓',
  'country.PN': '皮特凱恩',
  'country.PL': '波蘭',
  'country.PT': '葡萄牙',
  'country.PR': '波多黎各',
  'country.QA': '卡塔爾',
  'country.RE': '團圓',
  'country.RO': '羅馬尼亞',
  'country.RU': '俄國',
  'country.RQ': '俄羅斯聯邦',
  'country.RW': '盧旺達',
  'country.SH': '聖海倫娜',
  'country.KN': '聖基茨和尼維斯',
  'country.LC': '聖盧西亞',
  'country.PM': '聖皮埃爾和密克隆群島',
  'country.VC': '聖文森特和格林納丁斯',
  'country.WS': '薩摩亞',
  'country.SM': '聖馬力諾',
  'country.ST': '聖多美和普林西比',
  'country.SA': '沙特阿拉伯',
  'country.SN': '塞內加爾',
  'country.RS': '塞爾維亞',
  'country.SC': '塞舌爾',
  'country.SL': '塞拉利昂',
  'country.SG': '新加坡',
  'country.SK': '斯洛伐克',
  'country.SI': '斯洛文尼亞',
  'country.SB': '所羅門群島',
  'country.SO': '索馬里',
  'country.ZA': '南非',
  'country.GS': '南喬治亞島和南桑威奇群島',
  'country.ES': '西班牙',
  'country.LK': '斯里蘭卡',
  'country.SD': '蘇丹',
  'country.SR': '蘇里南',
  'country.SJ': '斯瓦爾巴群島和揚馬延群島',
  'country.SZ': '斯威士蘭',
  'country.SE': '瑞典',
  'country.CH': '瑞士',
  'country.SY': '敘利亞',
  'country.TW': '台灣',
  'country.TJ': '塔吉克斯坦',
  'country.TZ': '坦桑尼亞',
  'country.TH': '泰國',
  'country.TG': '多哥',
  'country.TK': '托克勞',
  'country.TO': '湯加',
  'country.TT': '特立尼達和多巴哥',
  'country.TN': '突尼斯',
  'country.TR': '土耳其',
  'country.TM': '土庫曼斯坦',
  'country.TC': '特克斯和凱科斯群島',
  'country.TV': '圖瓦盧',
  'country.UG': '烏干達',
  'country.UA': '烏克蘭',
  'country.AE': '阿拉伯聯合酋長國',
  'country.US': '美國',
  'country.UM': '美國本土外小島嶼',
  'country.XX': '未知',
  'country.UY': '烏拉圭',
  'country.UZ': '烏茲別克斯坦',
  'country.VU': '瓦努阿圖',
  'country.VA': '梵蒂岡城國',
  'country.VE': '委內瑞拉',
  'country.VN': '越南',
  'country.VG': '英屬維爾京群島',
  'country.VI': '美國維爾京群島',
  'country.WF': '瓦利斯群島和富圖納群島',
  'country.EH': '撒哈拉沙漠西部',
  'country.YE': '也門',
  'country.ZR': '扎伊爾',
  'country.ZM': '贊比亞',
  'country.ZW': '津巴布韋',

  'country.AX': '奧蘭群島',
  'country.AC': '升天島',
  'country.BL': '聖巴托洛繆島',
  'country.GG': '根西島',
  'country.JE': '澤西島',
  'country.MF': '聖馬丁（法語部分）',
  'country.PS': '被佔領的巴勒斯坦領土',
  'country.TA': '特里斯坦達庫尼亞',
  'country.TL': '東帝汶',
  'country.XK': '科索沃',

  'country.SS1': '南蘇丹',
  'country.SX1': '聖馬丁（荷蘭語部分）',
};
