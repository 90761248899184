export enum AppRoute {
  review = 'routes.review',
  hdfForm = 'routes.hdfForm',
  registration = 'routes.registration',
  confirm = 'routes.confirm',
  error = 'routes.error',
  checkinpayment = 'routes.checkinpayment',
  confirmpayment = 'routes.confirmpayment',
  search = 'routes.search',
}

export const AppRouteTitles = new Map([
  [AppRoute.review, 'review.menu.name'],
  [AppRoute.hdfForm, 'review.menu.hdfForm'],
  [AppRoute.registration, 'registration.menu.name'],
  [AppRoute.confirm, 'confirm.menu.name'],
  [AppRoute.error, 'error.menu.name'],
  [AppRoute.checkinpayment, 'error.menu.name'],
  [AppRoute.confirmpayment, 'error.menu.name'],
  [AppRoute.search, 'search.menu.name'],
]);
