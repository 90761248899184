import { Button, CircularProgress, TextField } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import { regToken, searchPrearrivalBooking } from 'api';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory, Redirect } from 'react-router-dom';
const { REACT_APP_API_URL } = process.env;

export const SearchPage = (props: { token: string; hotelId: string }) => {
  const intl = useIntl();
  const [initialValues, setInitialValues] = useState({
    confirmationNumber: '',
    lastName: '',
    firstName: '',
  });
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const history = useHistory();

  useEffect(() => {
    if (
      window.location.href.startsWith('https://precheckin.royalplaza.com.hk/')
    ) {
      regToken('633e5647d02dd2bf990ed96c').then((token) =>
        localStorage.setItem('token', token)
      );
    }
  }, [props]);

  const onSearchClick = async () => {
    setErrorMessage('');
    setIsLoading(true);
    try {
      const result = await searchPrearrivalBooking(
        initialValues.confirmationNumber.trim(),
        initialValues.lastName,
        initialValues.firstName
      );
      window.location.replace(result.value.url);
    } catch (err: any) {
      setErrorMessage(intl.formatMessage({ id: 'error.message.notFound' }));
    }
    setIsLoading(false);
  };

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    field: string
  ) => {
    const value = event.target.value;
    setInitialValues((prev) => ({ ...prev, [field]: value }));
  };

  return (
    <Container component="main" maxWidth="sm">
      <CssBaseline />
      <Grid container spacing={2} style={{ width: '100%' }}>
        <Grid item container>
          <Grid item xs={8} sm={4} style={{ color: '#808080' }}>
            <FormattedMessage id="review.confirm.no" />
          </Grid>
          <Grid item xs={12} sm={8}>
            <TextField
              style={{ width: '100%' }}
              value={initialValues.confirmationNumber || ''}
              onChange={(e) => handleChange(e, 'confirmationNumber')}
            />
          </Grid>
        </Grid>
        <Grid item container>
          <Grid item xs={12} sm={4} style={{ color: '#808080' }}>
            <FormattedMessage id="reg.lastName" />
          </Grid>
          <Grid item xs={12} sm={8}>
            <TextField
              style={{ width: '100%' }}
              value={initialValues.lastName || ''}
              onChange={(e) => handleChange(e, 'lastName')}
            />
          </Grid>
        </Grid>
        <Grid item container>
          <Grid item xs={12} sm={4} style={{ color: '#808080' }}>
            <FormattedMessage id="reg.firstName" />
          </Grid>
          <Grid item xs={12} sm={8}>
            <TextField
              style={{ width: '100%' }}
              value={initialValues.firstName || ''}
              onChange={(e) => handleChange(e, 'firstName')}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Button
            onClick={() => onSearchClick()}
            disabled={isLoading}
            style={{
              width: '100%',
              background:
                'linear-gradient(180deg, #FFAB00 10.94%, #FFDE72 100%)',
              color: '#fff',
            }}
          >
            {isLoading ? <CircularProgress size={16} /> : 'Search'}
          </Button>
        </Grid>
        <Grid item xs={12}>
          {errorMessage.length > 0 && (
            <div style={{ color: 'red' }}>{errorMessage}</div>
          )}
        </Grid>
      </Grid>
    </Container>
  );
};
